import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import * as css from "@css/projects/p-item-list.module.styl"
// import Tags from "@projects/Tags"
import { Image } from "@atoms/Image"
import { TransitionLink } from "../atoms/Link"
import dayjs from "dayjs"

function ItemList({ data, emptyImg}) {
    const q = useStaticQuery(graphql`
        query {
            dammyItem : file(relativePath: { eq: "Common__img__empty.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 240
                        placeholder: BLURRED
                        formats: [AUTO]
                        quality: 100
                        layout: FULL_WIDTH
                    )
                }
            }
        }
    `)
    return (
        <div className={css.pItemList}>
            {data.length === 0 ?
                <div className={css.aEmpty}>
                    <h5 className={css.aTitle}>Empty</h5>
                    <p className={css.aText}>まだ投稿がありません。</p>
                </div>
                :
                <ul>
                    {data.map(({node}, i) => {
                        // console.log("=>",node)
                        let { thumbnail, slug, title, date, tags, acf_item} = node
                        let { price, overridePrice } = acf_item
                        price = price ? `¥${overridePrice || price}` : ''
                        let newer = dayjs(date).diff(dayjs(), 'day') >= -6
                        // let pickup = tags.nodes[0] && tags.nodes[0].name === 'pickup'
                        console.log("=>",newer)
                        const makeTags = () => {
                            let dom = []
                            for(let tag of tags.nodes){
                                dom.push(<span>{tag.name}</span>)
                            }
                            return dom
                        }
                        return (
                            <li key={i}>
                                <TransitionLink className={css.aInner} to={`/item/${slug}`}>
                                    { thumbnail ?
                                        <Image data={thumbnail.node.localFile.childImageSharp} />
                                        :
                                        <Image data={q.dammyItem.childImageSharp} />
                                    }
                                    <div className={css.pDetail}>
                                        <p className={css.aTitle}>{title}</p>
                                        <p className={css.aPrice}>{price}</p>
                                    </div>
                                    <div className={css.pItemBatch}>
                                        { newer && <span>新着</span> }
                                        { makeTags() }
                                    </div>
                                </TransitionLink>
                            </li>
                        )
                    })}
                </ul>
            }
        </div>
    )
}

export { ItemList }