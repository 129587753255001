// extracted by mini-css-extract-plugin
export var aArrow1 = "zc";
export var aArrow2 = "Ac";
export var aEmpty = "pc";
export var aPrice = "sc";
export var aTagAnnounce = "wc";
export var aTagClose = "vc";
export var aTagEntry = "tc";
export var aTagFree = "yc";
export var aTagLottery = "xc";
export var aTagPayment = "uc";
export var aText = "Bc";
export var aTitle = "qc";
export var animationMarquee = "Fc";
export var pDetail = "Ec";
export var pItemBatch = "Cc";
export var pItemBatchAbs = "Dc";
export var pItemList = "rc";