import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { Image } from "@atoms/Image"
// import Prism from '@atoms/Prism'
import SEO from "@atoms/Seo"
import * as Spacer from "@atoms/Spacer"
import { ItemList } from "@projects/ItemList"
import { MarqueePageTitle } from "@projects/MarqueePageTitle"

import * as css from "@css/layouts/l-items.module.styl"

// import { gsap } from "gsap"
// import { ScrollTrigger } from 'gsap/ScrollTrigger'
// gsap.registerPlugin(ScrollTrigger)

function ItemListPage({data, pageContext})
{
    const { categorysByName, category } = pageContext
    const catName = categorysByName ? categorysByName.fieldValue : ''
    const { posts } = data
    // const [categorizePostData, setCategorizePostData] = useState()
    console.log("=>",category)
    
    // const sortPostByCats = () => {
    //     const result = {}
    //     for(let i in posts.edges){
    //         const { node } = posts.edges[i]
    //         const cat = node.cat.nodes[0].slug
    //         if( !result[cat] ){
    //             result[cat] = []
    //         }
    //         result[cat].push(node)
    //     }
    //     setCategorizePostData(result)
    // }

    // useEffect(()=>{
    //     sortPostByCats()
    // },[])
    // useEffect(()=>{

    // },[categorizePostData])

    return(
        <>
            <SEO
                // subtitle={title}
                // description={content}
                // image={thumbnail && `${thumbnail.node.localFile.publicURL}`}
            />
            <Spacer.Header/>
            <main className={css.lItems}>
                <MarqueePageTitle title={category.toUpperCase()}/>
                <div className={css.aInner}>
                    <ItemList data={posts.edges}/>
                </div>
            </main>
        </>
    )
}

export const pagerQuery = graphql`
    
    query ItemListBySlug($categoryReg: String!){
        posts : allWpPost(
            filter: {
                acf_item: {isHide: {ne: true}}
                categories: {nodes: {elemMatch: {slug: {regex: $categoryReg}}}}
            }
            sort: {order: DESC, fields: date}
            limit: 9999
        ) {
            edges {
                node {
                    ...Item
                }
            }
        }
    }
    
`
export default ItemListPage